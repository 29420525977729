import * as React from "react"
import { useContext } from "react"
import {
  Header,
  VideoContent,
  Footer,
  BottomNavbar,
  NoAuth,
  NoAccess,
  Loader,
} from "../components/index"
import { Helmet } from "react-helmet"
import { graphql } from "gatsby"
import { useAuth0 } from "@auth0/auth0-react"

import mockup from "../utils/data"

// Context
import UserContext from "../context/UserAuth"

// mockup.video_library.list

const IndexPage = ({ data }) => {
  const { isAuthenticated, isAccess, isLoading } = useContext(UserContext) || {
    isAuthenticated: false,
    isAccess: false,
    isLoading: false,
  }

  console.log("videos", data)

  return (
    <>
      <Helmet>
        <title>Esteekey - Videos</title>
        <meta name="description" content="Esteekey - #1 Fitness app for 2022" />
        <meta name="theme-color" content="#ffffff" data-react-helmet="true" />
      </Helmet>
      <Header />
      <div className="min-h-[calc(100vh-158px)] pt-[69px] xl:pt-0">
        {isLoading && <Loader />}
        {!isAuthenticated ? (
          <NoAuth />
        ) : !isAccess ? (
          <NoAccess />
        ) : (
          <VideoContent
            videos={data?.videos?.edges || mockup.video_library.list}
            category={data.videoTags.nodes}
          />
        )}
      </div>
      <BottomNavbar activePage="videos" />
      <Footer />
    </>
  )
}

export const query = graphql`
  query QueryVids {
    videoTags: allSanityVideoTag {
      nodes {
        name
        nameRU
      }
    }
    videos: allSanityVideo(sort: { fields: _updatedAt, order: ASC }) {
      edges {
        node {
          id
          link
          title
          titleRU
          tags {
            name
            nameRU
          }
          downloadFiles {
            asset {
              id
              url
            }
          }
          hidden
        }
      }
    }
  }
`

export default IndexPage
